/* eslint-disable no-param-reassign */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	personsList: []
});

const persons = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_PERSONS:
			if (action.state === 'finished') {
				state = state.set('personsList', action.response);
			}
			return state;

		case actionTypes.UPDATE_PERSON:
			if (action.state === 'finished') {
				const updatedPerson = action.response.toJS();
				/* Change the default person after the registration if is needed. */
				state = state.setIn(
					['personsList', 0],
					fromJS({
						personName: updatedPerson.name,
						photo: updatedPerson.photo,
						id: updatedPerson.id
					})
				);
			}
			return state;
		default:
			return state;
	}
};

export default persons;
