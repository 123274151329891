/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	TextField,
	Avatar,
	Button,
	CssBaseline,
	Container,
	Link,
	Paper,
	Box,
	Grid,
	Typography,
	FormHelperText,
	InputAdornment,
	IconButton,
	OutlinedInput,
	FormControl,
	InputLabel
} from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { withStyles } from '@material-ui/styles';
import Copyrights from '@Components/Copyrights/index';
import RenderLink from '@Components/RenderLink/index';

import { isNumeric } from '@Common';

import { STRONG_PASS_REGEX, EMAIL_REGEX } from '@Constants';

import { logIn, registerUser } from '../actions';
import AgreeDialog from '../components/AgreeDialog/index';
import { registerStyles } from './styles';

class Register extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {
				agree: false
			},
			errors: {},
			showPassword: false,
			showRepPassword: false
		};
	}

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	handleAgreeChange = isAgree => {
		const { fields } = this.state;
		fields.agree = isAgree;
		this.setState({ fields, errors: {} });
	};

	onFormSubmit = async event => {
		event.preventDefault();
		if (this.handleValidation()) {
			const { fields } = this.state;
			const regData = {
				firstName: fields.firstName,
				lastName: fields.lastName,
				email: fields.email.toLowerCase(),
				password: fields.password,
				phoneNumber: fields.phoneNumber,
				isDefaultPersonExist: true
			};

			try {
				const registrationResponse = await this.props.registerUser(
					regData
				);

				if (
					registrationResponse.response.has('code') &&
					registrationResponse.response.get('code') === 400
				) {
					return;
				}
				try {
					await this.props.logIn({
						email: fields.email,
						password: fields.password
					});

					this.props.history.push({
						pathname: '/',
						state: { isCreateDialogOpen: true }
					});
				} catch (e) {
					// eslint-disable-next-line no-console
					console.log(e, 'login error');
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log(e, 'registration error');
			}
		}
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.firstName) {
			formIsValid = false;
			errors.firstName = 'Field cannot be empty.';
		}

		if (!fields.lastName) {
			formIsValid = false;
			errors.lastName = 'Field cannot be empty.';
		}

		if (!fields.email) {
			formIsValid = false;
			errors.email = 'Field cannot be empty.';
		} else if (!EMAIL_REGEX.test(fields.email)) {
			formIsValid = false;
			errors.email = 'Email format is not valid.';
		}

		if (!fields.password) {
			formIsValid = false;
			errors.password = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.password)) {
			formIsValid = false;
			errors.password =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (!fields.repPassword) {
			formIsValid = false;
			errors.repPassword = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.repPassword)) {
			formIsValid = false;
			errors.repPassword =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (
			fields.password &&
			fields.repPassword &&
			fields.password !== fields.repPassword
		) {
			formIsValid = false;
			errors.repPassword = 'Passwords not match.';
		}

		if (!fields.agree) {
			formIsValid = false;
			errors.agree = 'Please accept user agreements.';
		}

		if (!fields.phoneNumber) {
			formIsValid = false;
			errors.phoneNumber = 'Field cannot be empty.';
		} else if (!isNumeric(fields.phoneNumber)) {
			formIsValid = false;
			errors.phoneNumber =
				'Phone number format is not valid. It can be only numbers';
		}

		this.setState({ errors });

		return formIsValid;
	};

	handleClickShowPassword = () => {
		const { showPassword } = this.state;
		this.setState({ showPassword: !showPassword });
	};

	handleClickShowRepPassword = () => {
		const { showRepPassword } = this.state;
		this.setState({ showRepPassword: !showRepPassword });
	};

	render = () => {
		const { classes } = this.props;
		const { errors, fields, showPassword, showRepPassword } = this.state;

		return (
			<div>
				<Paper className={classes.mainImage} />
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign Up
						</Typography>
						<form
							onSubmit={this.onFormSubmit}
							className={classes.form}
							noValidate
						>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										autoComplete="fname"
										name="firstName"
										onChange={this.onChangeHandler}
										variant="outlined"
										required
										fullWidth
										value={fields.firstName}
										error={errors.firstName}
										helperText={errors.firstName}
										id="firstName"
										label="First Name"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										value={fields.lastName}
										error={errors.lastName}
										helperText={errors.lastName}
										onChange={this.onChangeHandler}
										autoComplete="lname"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										value={fields.email}
										onChange={this.onChangeHandler}
										error={errors.email}
										helperText={errors.email}
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="phone_number"
										label="Phone Number"
										name="phoneNumber"
										value={fields.phoneNumber}
										onChange={this.onChangeHandler}
										error={errors.phoneNumber}
										helperText={errors.phoneNumber}
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl style={{ width: '100%' }}>
										<InputLabel
											htmlFor="registration-password"
											fullWidth
											variant="outlined"
										>
											Password
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											required
											fullWidth
											name="password"
											onChange={this.onChangeHandler}
											error={errors.password}
											helperText={errors.password}
											label="Password"
											value={fields.password}
											type={
												showPassword
													? 'text'
													: 'password'
											}
											id="registration-password"
											inputProps={{
												autocomplete: 'new-password',
												form: {
													autocomplete: 'off'
												}
											}}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={
															this
																.handleClickShowPassword
														}
													>
														{showPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											}
										/>
										{errors.password && (
											<FormHelperText
												style={{ color: '#f44336' }}
												id="error-password-text"
											>
												{errors.password}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControl style={{ width: '100%' }}>
										<InputLabel
											htmlFor="rep-password"
											fullWidth
											variant="outlined"
										>
											Confirm Password
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											required
											fullWidth
											name="repPassword"
											onChange={this.onChangeHandler}
											error={errors.repPassword}
											helperText={errors.repPassword}
											label="Confirm Password"
											value={fields.repPassword}
											type={
												showRepPassword
													? 'text'
													: 'password'
											}
											id="rep-password"
											inputProps={{
												autocomplete: 'new-password',
												form: {
													autocomplete: 'off'
												}
											}}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={
															this
																.handleClickShowRepPassword
														}
													>
														{showRepPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											}
										/>
										{errors.repPassword && (
											<FormHelperText
												style={{ color: '#f44336' }}
												id="error-rep-password-text"
											>
												{errors.repPassword}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<AgreeDialog
										handleAgree={this.handleAgreeChange}
									/>
									{errors.agree && (
										<FormHelperText
											className={classes.agreeError}
										>
											Please indicate that you have read
											and agree to the Terms and
											Conditions and Privacy Policy
										</FormHelperText>
									)}
								</Grid>
							</Grid>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								/* 	disabled={!fields.agree} */
							>
								Create your account
							</Button>
							<Grid container justify="flex-end">
								<Grid item>
									<RenderLink
										element={Link}
										to="/login"
										variant="body2"
									>
										Already have an account? Sign in
									</RenderLink>
								</Grid>
							</Grid>
						</form>
					</div>
					<Box mt={5}>
						<Copyrights />
					</Box>
				</Container>
			</div>
		);
	};
}

Register.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	logIn: PropTypes.func.isRequired,
	registerUser: PropTypes.func.isRequired
};

export default withStyles(registerStyles)(
	connect(null, {
		logIn,
		registerUser
	})(Register)
);
