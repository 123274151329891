import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import usaFlag from '@Images/united-states-of-america-flag-icon-64.png';
import spanishFlag from '@Images/spain-flag-icon-64.png';

export const useStyles = makeStyles(theme => ({
	listItem: {
		'&:hover': {
			background: theme.palette.primary.hover,
			'&:before': {
				transform: 'scale(1)'
			}
		}
	},
	languageMenuButton: {
		[theme.breakpoints.down('sm')]: {
			padding: 5,
			minWidth: 40
		}
	},
	languageAvatar: {
		width: 35,
		height: 22,
		[theme.breakpoints.down('sm')]: {
			width: 30,
			height: 20
		}
	}
}));

const LanguageMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useStyles();
	const openMenu = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<Button
				aria-controls="language-menu"
				color="inherit"
				onClick={handleMenu}
				className={classes.languageMenuButton}
			>
				<Avatar
					className={classes.languageAvatar}
					variant="square"
					alt="My PHI language switcher"
					src={usaFlag}
				/>
			</Button>
			<Menu
				id="language-menu"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={openMenu}
				onClose={handleClose}
			>
				<MenuItem className={classes.listItem} onClick={handleClose}>
					<Avatar
						style={{
							height: '21px',
							width: '34px',
							marginTop: '4px'
						}}
						variant="square"
						alt="My PHI"
						src={usaFlag}
					/>
					<Typography
						color="inherit"
						noWrap
						style={{
							marginLeft: '7px',
							marginTop: '3px'
						}}
					>
						English
					</Typography>
				</MenuItem>
				<MenuItem className={classes.listItem} onClick={handleClose}>
					<Avatar
						style={{
							height: '21px',
							width: '34px',
							marginTop: '4px'
						}}
						variant="square"
						alt="My PHI"
						src={spanishFlag}
					/>
					<Typography
						color="inherit"
						noWrap
						style={{
							marginLeft: '7px',
							marginTop: '3px'
						}}
					>
						Spanish
					</Typography>
				</MenuItem>
			</Menu>
		</Fragment>
	);
};

export default LanguageMenu;
