import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { CssBaseline, Grid, Typography, Avatar, Box } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import { withStyles } from '@material-ui/styles';

import TimelineIcon from '@material-ui/icons/Timeline';

/* import EditOutlinedIcon from '@material-ui/icons/EditOutlined'; */
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { setSelectedUser, setSelectedPerson } from '@SharedActions';
import EditPersonModal from '@Components/Modals/EditPersonModal';
import TimelineData from '@Components/TimelineList/TimelineData/index';
import News from '@Components/News';

import { styles } from './styles';
import { CARDS } from './constants';
import {
	updatePerson,
	photoUpload,
	deletePerson,
	getPerson,
	getProvidersWaitingForSms,
	sendSmsCode,
	getTimelineList,
	getContent,
	getPersonNews
} from './actions';

class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openEditPersonModal: false
		};
	}

	componentDidMount = async () => {
		this.getPersonInformation();
	};

	componentDidUpdate = async prevProps => {
		if (
			this.props.match.params.personId !== prevProps.match.params.personId
		) {
			this.getPersonInformation();
		}
	};

	getPersonInformation = async () => {
		const { personId } = this.props.match.params;
		const { history } = this.props;

		const res = await this.props.getPerson(personId);

		await this.props.getTimelineList(personId);
		await this.props.getPersonNews(personId);
		const person = {
			name: res.response.get('name'),
			id: personId,
			photo: res.response.get('photo')
		};

		this.props.setSelectedUser(person);

		/* Open first my chart profile */
		/* await this.props.getProfilesList(personId); */

		this.props.getProvidersWaitingForSms(personId);

		const newPersonState = {
			person
		};

		this.props.setSelectedPerson(newPersonState.person);

		history.replace({ ...history.location, state: newPersonState });
	};

	deletePerson = async () => {
		try {
			await this.props.deletePerson(this.props.person.id);
			this.props.history.push('/');
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	updatePerson = async personData => {
		const { history, person } = this.props;

		try {
			const res = await this.props.updatePerson(personData);

			const newPersonState = {
				person: {
					...person,
					name: res.response.get('name'),
					photo: res.response.get('photo')
				}
			};

			history.replace({ ...history.location, state: newPersonState });

			this.props.setSelectedUser({
				id: person.id,
				name: res.response.get('name'),
				photo: res.response.get('photo')
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	editPerson = async (personData, formData) => {
		try {
			if (formData) {
				const res = await this.props.photoUpload(formData);
				// eslint-disable-next-line no-param-reassign
				personData.photo = res.response.get('fileName');
			}

			this.updatePerson({
				...personData
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	handleSendSmsCode = async (smsCode, providerId) => {
		try {
			await this.props.sendSmsCode(smsCode, providerId);

			const { person } = this.props;

			await this.props.getProvidersWaitingForSms(person.id);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e);
		}
	};

	handleOpenEditPersonModal = () => {
		this.setState({ openEditPersonModal: true });
	};

	handleCloseEditPersonModal = () => {
		this.setState({ openEditPersonModal: false });
	};

	render = () => {
		const { classes, person, timelineData, news } = this.props;
		const { openEditPersonModal } = this.state;

		return (
			<>
				<CssBaseline />
				<Grid
					container
					item
					className={classes.dashboardContent}
					spacing={3}
				>
					<Grid item xs={12} sm md={5}>
						<Grid container item spacing={3} direction="column">
							<Grid item>
								<Grid container item spacing={3}>
									<Grid
										item
										className={classes.avatarContent}
									>
										<Box>
											<Avatar
												className={classes.avatar}
												alt={person.name}
												src={person.photo}
											/>
										</Box>
										<Box
											display="flex"
											alignItems="end"
											marginLeft="-25px"
										>
											<EditIcon
												variant="contained"
												color="primary"
												style={{ cursor: 'pointer' }}
												onClick={
													this
														.handleOpenEditPersonModal
												}
											/>
										</Box>
									</Grid>
									<Grid
										item
										xs={12}
										sm
										style={{
											alignItems: 'flex-start',
											direction: 'column'
										}}
										className={classes.userActionButtons}
									>
										<RouterLink
											className={classes.link}
											to={{
												pathname: `/dashboard/${person.id}/export-summary`,
												state: {
													person
												}
											}}
										>
											<div className={classes.userAction}>
												<div
													className={
														classes.actionIcon
													}
												>
													<ShareOutlinedIcon />
												</div>
												<Typography
													style={{ fontWeight: 400 }}
												>
													Share My Records
												</Typography>
											</div>
										</RouterLink>
										{/* It will uncomment with task MYP-274 <RouterLink
											className={classes.link}
											to={{
												pathname: `/dashboard/${person.id}/new-self-profile`,
												state: {
													person,
													showMenu: true
												}
											}}
										>
											<div className={classes.userAction}>
												<div
													className={
														classes.actionIcon
													}
												>
													<EditOutlinedIcon />
												</div>
												<Typography
													style={{ fontWeight: 400 }}
												>
													Edit My Info
												</Typography>
											</div>
										</RouterLink> */}
										<RouterLink
											className={classes.link}
											to={{
												pathname: `/dashboard/${person.id}/timeline`,
												state: {
													person
												}
											}}
										>
											<div className={classes.userAction}>
												<div
													className={
														classes.actionIcon
													}
												>
													<TimelineIcon />
												</div>
												<Typography
													style={{ fontWeight: 400 }}
												>
													View My Health Timeline
												</Typography>
											</div>
										</RouterLink>
										<RouterLink
											className={classes.link}
											to={{
												pathname: `/dashboard/${person.id}/profiles/${person.profileId}`,
												state: {
													person,
													profile: {
														profileDetailId:
															person.profileDetailId,
														id: person.profileId,
														name:
															person.profileName,
														provider: {
															id:
																person.providerId
														}
													}
												}
											}}
										>
											<div className={classes.userAction}>
												<div
													className={
														classes.actionIcon
													}
												>
													<SettingsIcon />
												</div>
												<Typography
													style={{ fontWeight: 400 }}
												>
													View My Health Info
												</Typography>
											</div>
										</RouterLink>
										<RouterLink
											className={classes.link}
											to={{
												pathname: `/dashboard/${person.id}/new-provider-profile`,
												state: {
													person
												}
											}}
										>
											<div className={classes.userAction}>
												<div
													className={
														classes.actionIcon
													}
												>
													<AddCircleIcon />
												</div>
												<Typography
													style={{ fontWeight: 400 }}
												>
													Add Connection
												</Typography>
											</div>
										</RouterLink>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className={classes.timelineGrid}>
								{timelineData.length > 0 ? (
									<TimelineData
										data={timelineData}
										getContent={this.props.getContent}
										isSmallContainer
									/>
								) : (
									<Typography
										variant="h6"
										align="center"
										color="textSecondary"
										className={classes.subHeader}
										style={{
											marginTop: '100px'
										}}
									>
										This person has no timeline data
									</Typography>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm md={7}>
						<Box>
							<Box minHeight="500px">
								{news.length > 0 ? (
									<News data={news} />
								) : (
									<Typography
										variant="h6"
										align="center"
										color="textSecondary"
										className={classes.subHeader}
										style={{
											marginTop: '100px'
										}}
									>
										This person has no news data
									</Typography>
								)}
							</Box>
							<Grid container spacing={3}>
								{CARDS.map(card => (
									<Grid
										item
										className={classes.cardWrapper}
										key={card.id}
										xs={6}
										sm
									>
										<RouterLink
											className={classes.link}
											to={{
												pathname: card.url(person.id),
												state: { person }
											}}
											style={{
												display: 'flex',
												justifyContent: 'center'
											}}
										>
											<Box
												display="flex"
												alignItems="center"
												flexDirection="column"
												justifyContent="center"
											>
												<Box>
													<Avatar
														className={
															classes.avatar
														}
														alt={card.title}
														src={card.image}
													/>
												</Box>
												<Box marginTop="20px">
													{card.title}
												</Box>
											</Box>
										</RouterLink>
									</Grid>
								))}
							</Grid>
						</Box>
					</Grid>
				</Grid>
				<EditPersonModal
					open={openEditPersonModal}
					editPersonHandler={this.editPerson}
					person={person}
					onClose={this.handleCloseEditPersonModal}
				/>
			</>
		);
	};
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	person: PropTypes.object.isRequired,
	updatePerson: PropTypes.func.isRequired,
	getPerson: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired,
	getPersonNews: PropTypes.func.isRequired,
	deletePerson: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	getProvidersWaitingForSms: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	setSelectedUser: PropTypes.func.isRequired,
	getTimelineList: PropTypes.func.isRequired,
	getContent: PropTypes.func.isRequired,
	timelineData: PropTypes.array.isRequired,
	news: PropTypes.array.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

Dashboard.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				person: state.dashboard.get('person').toJS(),
				profiles: state.dashboard.get('profiles').toJS(),
				timelineData: state.dashboard.get('timelineData').toJS(),
				news: state.dashboard.get('news').toJS(),
				providersWaitingForSms: state.dashboard
					.get('providersWaitingForSms')
					.toJS()
			};
		},
		{
			updatePerson,
			photoUpload,
			deletePerson,
			getPerson,
			getProvidersWaitingForSms,
			sendSmsCode,
			setSelectedPerson,
			setSelectedUser,
			getTimelineList,
			getContent,
			getPersonNews
		}
	)(Dashboard)
);
