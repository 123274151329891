import * as actionTypes from './actionTypes';

export const getVisitsList = personId => {
	return {
		type: actionTypes.GET_VISITS,
		requestType: 'GET',
		request: `/visits?person=${personId}&visitType=visitNote`,
		body: {
			personId
		}
	};
};

export const getVisit = visitId => {
	return {
		type: actionTypes.GET_VISIT,
		requestType: 'GET',
		request: `/visit?id=${visitId}`,
		body: { visitId }
	};
};

export const getProvider = providerId => {
	return {
		type: actionTypes.GET_PROVIDER,
		requestType: 'GET',
		request: `/provider?id=${providerId}`,
		body: { providerId }
	};
};

export const getProviderCredentials = profileId => {
	return {
		type: actionTypes.GET_PROVIDER_CREDENTIALS,
		requestType: 'GET',
		request: `/providerCredentialId?profile=${profileId}`
	};
};

export const updateProviderCredentials = credentialsData => {
	return {
		type: actionTypes.UPDATE_PROVIDER_CREDENTIALS,
		message: 'Provider credentials are updated successful',
		requestType: 'POST',
		request: `/updateCredentials`,
		body: { ...credentialsData }
	};
};

export const deleteVisit = visitObj => {
	return {
		type: actionTypes.DELETE_VISIT,
		requestType: 'POST',
		message: 'Visit deleted successful',
		request: `/deleteVisit`,
		body: { ...visitObj }
	};
};

export const updateVisit = visitObj => {
	return {
		type: actionTypes.UPDATE_VISIT,
		requestType: 'POST',
		message:
			'Profile update request sent successful. You can check create status in Jobs.',
		request: `/updateVendorProfile`,
		body: { ...visitObj }
	};
};

export const resetData = () => {
	return {
		type: actionTypes.RESET_DATA,
		body: {}
	};
};

export const checkAccessCode = (code, guid) => {
	return {
		type: actionTypes.CHECK_ACCESS_CODE,
		requestType: 'GET',
		request: `/checkAccessCode?code=${code}&guid=${guid}`,
		body: { code, guid }
	};
};
export const loadVisitFromTempLink = guid => {
	return {
		type: actionTypes.LOAD_VISIT_FROM_TEMP_LINK,
		requestType: 'GET',
		request: `/tempLink?guid=${guid}`,
		body: { guid }
	};
};

export const sendTempLink = data => {
	return {
		type: actionTypes.SEND_TEMP_LINK,
		requestType: 'POST',
		message: 'Temp link was send successful. Please check your email.',
		request: `/tempLink`,
		body: {
			...data
		}
	};
};

export const getContent = fileName => {
	return {
		type: actionTypes.GET_CONTENT,
		requestType: 'GET',
		request: `/readFile?id=${fileName}`
	};
};

export const moveVisit = visitObj => {
	return {
		type: actionTypes.MOVE_VISIT,
		requestType: 'POST',
		message: 'Visit moved successful',
		request: `/moveVisit`,
		body: { ...visitObj }
	};
};

export const getPersonsList = userId => {
	return {
		type: actionTypes.GET_PERSONS,
		requestType: 'GET',
		request: `/people?user=${userId}`,
		body: {
			userId
		}
	};
};

export const getHealthSummaryList = personId => {
	return {
		type: actionTypes.GET_HEALTH_SUMMARIES,
		requestType: 'GET',
		request: `/visits?person=${personId}&visitType=health`,
		body: {
			personId
		}
	};
};
