/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import clsx from 'clsx';

import {
	CssBaseline,
	Grid,
	Paper,
	Drawer,
	Box,
	AppBar,
	Toolbar,
	Typography,
	Divider,
	IconButton,
	Container,
	Link,
	Breadcrumbs
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import MenuIcon from '@material-ui/icons/Menu';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles';

import CreatePersonModal from '@Components/Modals/CreatePersonModal';
import Copyrights from '@Components/Copyrights/index';

import ListItems from './components/ListItems/index';
import Messages from './components/Messages/index';
import LanguageMenu from './components/LanguageMenu/index';
import AccountMenu from './components/AccountMenu/index';

import cookies from '../../utilities/cookies';

/* TODO: Remove this - demo  */
import authentication from '../../utilities/authentication';

import { styles } from './styles';
import { breadcrumbNameMap } from './constants';

const useStyles = makeStyles(styles);

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const Home = ({
	logOut,
	children,
	sendFeedback,
	location,
	selectedPerson,
	messages,
	providersWaitingForSms,
	sendSmsCode,
	getJobs,
	selectedUser,
	persons,
	loggedAccountData,
	setSelectedUser,
	setSelectedPerson,
	addPerson,
	photoUpload
}) => {
	const classes = useStyles();
	const isSlideMenuOpen = cookies.get('isSlideMenuOpen');

	const [open, setOpen] = useState(isSlideMenuOpen === 'true');
	const [openCreatePersonModal, setOpenCreatePersonModal] = useState(false);
	const refContainer = useRef(null);

	const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));

	useEffect(() => {
		try {
			refContainer.current.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		} catch (error) {
			window.scrollTo(0, 0);
		}
	}, [location.pathname]);

	const handleDrawerOpen = () => {
		cookies.set('isSlideMenuOpen', true);
		setOpen(true);
	};

	const handleDrawerClose = () => {
		cookies.set('isSlideMenuOpen', false);
		setOpen(false);
	};

	const handleOpenCreatePersonModal = () => {
		setOpenCreatePersonModal(true);
	};

	const handleClose = () => {
		setOpenCreatePersonModal(false);
	};

	const userData = loggedAccountData;

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Box display="block" displayPrint="none">
				<AppBar
					position="absolute"
					className={clsx(
						classes.appBar,
						open && classes.appBarShift
					)}
				>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(
								classes.menuButton,
								open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							className={classes.title}
						>
							{matches
								? `Welcome to My PHI ${selectedUser.name}`
								: `${selectedUser.name}`}
						</Typography>
						<IconButton
							edge="start"
							color="inherit"
							styles={{ cursor: 'pointer' }}
							className={classes.personAddButton}
							onClick={handleOpenCreatePersonModal}
						>
							<PersonAddIcon />
						</IconButton>
						<Messages
							messages={messages}
							userId={userData.userId}
							location={location}
							getJobs={getJobs}
						/>
						<LanguageMenu />
						<AccountMenu
							authentication={authentication}
							logOut={logOut}
							selectedUser={selectedUser}
							userData={userData}
							persons={persons}
						/>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					classes={{
						paper: clsx(
							classes.drawerPaper,
							!open && classes.drawerPaperClose
						)
					}}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton
							className={classes.customHoverFocus}
							color="primary"
							onClick={handleDrawerClose}
						>
							<ChevronLeftOutlinedIcon />
						</IconButton>
					</div>
					<Divider />
					<ListItems
						sendFeedback={sendFeedback}
						userData={userData}
						location={location}
						selectedPerson={selectedPerson}
						providersWaitingForSms={providersWaitingForSms}
						sendSmsCode={sendSmsCode}
					/>
				</Drawer>
			</Box>
			<main
				id="main-content"
				ref={refContainer}
				className={classes.content}
			>
				<div className={classes.appBarSpacer} />

				<Container maxWidth="lg" className={classes.container}>
					<Paper className={classes.paper}>
						<Grid container spacing={3}>
							{/* Content */}
							<Grid item xs={12}>
								<Route>
									{({ location }) => {
										const pathNames = location.pathname
											.split('/')
											.filter(x => x);

										const { state } = location;
										return (
											<Box
												display="block"
												displayPrint="none"
											>
												<Breadcrumbs aria-label="breadcrumb">
													<LinkRouter
														color="inherit"
														to="/"
													>
														Home
													</LinkRouter>
													{pathNames.map(
														(value, index) => {
															const last =
																index ===
																pathNames.length -
																	1;
															const to = `/${pathNames
																.slice(
																	0,
																	index + 1
																)
																.join('/')}`;

															const title = breadcrumbNameMap(
																to,
																state
															);

															if (!title) {
																return null;
															}

															return last ? (
																<Typography
																	color="textPrimary"
																	key={to}
																>
																	{breadcrumbNameMap(
																		to,
																		state
																	)}
																</Typography>
															) : (
																<LinkRouter
																	color="inherit"
																	to={{
																		pathname: to,
																		state
																	}}
																	key={to}
																>
																	{breadcrumbNameMap(
																		to,
																		state
																	)}
																</LinkRouter>
															);
														}
													)}
												</Breadcrumbs>
											</Box>
										);
									}}
								</Route>
							</Grid>
							<Grid item xs={12} className={classes.pageContent}>
								{children}
							</Grid>
						</Grid>
					</Paper>
					<Box pt={4}>
						<Copyrights />
					</Box>
				</Container>
			</main>
			<CreatePersonModal
				open={openCreatePersonModal}
				addPerson={addPerson}
				photoUpload={photoUpload}
				handleClose={handleClose}
				loggedAccountData={loggedAccountData}
				setSelectedUser={setSelectedUser}
				setSelectedPerson={setSelectedPerson}
			/>
		</div>
	);
};

Home.propTypes = {
	children: PropTypes.array.isRequired,
	messages: PropTypes.array.isRequired,
	persons: PropTypes.array.isRequired,
	sendFeedback: PropTypes.func.isRequired,
	getJobs: PropTypes.func.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired,
	logOut: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	location: PropTypes.object,
	selectedPerson: PropTypes.object.isRequired,
	selectedUser: PropTypes.object.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	setSelectedUser: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	addPerson: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired
};

Home.defaultProps = {
	location: {}
};

export default Home;
