/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

import profileDashboard, {
	defaultState as profileDashboardDefaultState
} from './pages/Profiles/reducer';

import visitDashboard, {
	defaultState as visitDashboardDefaultState
} from './pages/Visits/reducer';

export const defaultState = fromJS({
	profileDashboard: profileDashboardDefaultState,
	visitDashboard: visitDashboardDefaultState,
	providersWaitingForSms: [],
	timelineData: [],
	news: [],
	person: {},
	profiles: {
		education: {},
		myChart: {}
	}
});

const PROFILE_DASHBOARD = 'profileDashboard';
const VISIT_DASHBOARD = 'visitDashboard';

export default function dashboard(state = defaultState, action) {
	switch (action.type) {
		case actionTypes.GET_PERSON:
			if (action.state === 'finished') {
				state = state.set('person', action.response);
			}
			return state;
		case actionTypes.UPDATE_PERSON:
			if (action.state === 'finished') {
				state = state.set('person', action.response);
			}
			return state;
		case actionTypes.GET_PROVIDERS_WAITING_FOR_SMS:
			if (action.state === 'finished') {
				const providersWaitingForSms = action.response.filter(
					item => !item.get('code')
				);

				state = state.set(
					'providersWaitingForSms',
					providersWaitingForSms
				);
			}
			return state;
		case actionTypes.GET_PROFILES:
			if (action.state === 'finished') {
				/* Temporary code  */

				if (action.response.toJS().length === 0) {
					state = state.set(
						'profiles',
						fromJS({
							education: {},
							myChart: {}
						})
					);
				}

				const allProfiles = action.response.toJS().sort((a, b) => {
					return new Date(a.created) - new Date(b.created);
				});
				let myChartFlag = false;
				let educationFlag = false;

				for (let i = 0; i < allProfiles.length; i++) {
					if (
						allProfiles[i].profileName.includes(
							'SELF PROVIDER MYCHART'
						)
					) {
						state = state.setIn(
							['profiles', 'myChart'],
							fromJS(allProfiles[i])
						);
						myChartFlag = true;
						break;
					}
				}

				for (let i = 0; i < allProfiles.length; i++) {
					if (
						allProfiles[i].profileName.includes(
							'SELF PROVIDER EDUCATION'
						)
					) {
						state = state.setIn(
							['profiles', 'education'],
							fromJS(allProfiles[i])
						);
						educationFlag = true;
						break;
					}
				}
				if (!myChartFlag) {
					state = state.setIn(['profiles', 'myChart'], fromJS({}));
				}

				if (!educationFlag) {
					state = state.setIn(['profiles', 'education'], fromJS({}));
				}
			}

			/* End Temporary code  */
			return state;
		case actionTypes.GET_TIMELINE_LIST:
			if (action.state === 'finished') {
				const lastFiveElements = action.response
					.toJS()
					.filter((val, index, arr) => index > arr.length - 6);

				state = state.set('timelineData', fromJS(lastFiveElements));
			}

			return state;
		case actionTypes.GET_PERSON_NEWS:
			if (action.state === 'finished') {
				state = state.set('news', action.response);
			}
			return state;

		default:
			return state
				.set(
					PROFILE_DASHBOARD,
					profileDashboard(state.get(PROFILE_DASHBOARD), action)
				)
				.set(
					VISIT_DASHBOARD,
					visitDashboard(state.get(VISIT_DASHBOARD), action)
				);
	}
}
