/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { fromJS } from 'immutable';

import {
	OPEN_ALERT,
	LOG_IN,
	SET_SELECTED_USER,
	SET_DEFAULT_SELECTED_USER,
	SET_LOGGED_ACCOUNT_DATA,
	SET_SELECTED_PERSON,
	CLEAR_SELECTED_PERSON_INFO
} from '@SharedActions';
import { CLOSE_ALERT, GET_MESSAGES } from './actions';

import jobs from './pages/Jobs/reducer';
import consent from './pages/Consent/reducer';
import selfProfile from './pages/SelfProfile/reducer';
import admin from './pages/Admin/reducer';
import dashboard from './pages/Dashboard/reducer';
import reports from './pages/Reports/reducer';
import summary from './pages/Summary/reducer';
import persons from './pages/Persons/reducer';
import timeline from './pages/Timeline/reducer';
import connection from './pages/Connection/reducer';
import unauthenticatedPages from './pages/UnauthenticatedPages/reducer';
import accountPages from './pages/Authentication/reducer';

import authentication from './utilities/authentication';

export const defaultState = fromJS({
	isOpen: false,
	isAlertOpen: false,
	isLoginSuccessful: false,
	selectedUser: {
		name: 'Admin',
		id: '',
		photo: null
	},
	messages: [],
	alertInfo: {
		severity: 'success',
		message: 'This is a success message!'
	},
	loggedAccountData: {},
	selectedPerson: {}
});

const general = (state = defaultState, action) => {
	switch (action.type) {
		case CLOSE_ALERT: {
			return state.set('isAlertOpen', false);
		}
		case SET_SELECTED_USER: {
			state = state
				.setIn(['selectedUser', 'id'], action.body.id)
				.setIn(['selectedUser', 'photo'], action.body.photo)
				.setIn(['selectedUser', 'name'], action.body.name);

			return state;
		}
		case SET_DEFAULT_SELECTED_USER: {
			const userData = state.get('loggedAccountData').toJS();

			state = state
				.setIn(['selectedUser', 'id'], userData.userId)
				.setIn(['selectedUser', 'photo'], null)
				.setIn(['selectedUser', 'name'], userData.username);

			return state;
		}
		case SET_LOGGED_ACCOUNT_DATA: {
			state = state
				.setIn(['loggedAccountData', 'username'], action.body.username)
				.setIn(['selectedUser', 'name'], action.body.username);

			return state;
		}
		case OPEN_ALERT: {
			return state
				.setIn(['alertInfo', 'severity'], action.body.severity)
				.setIn(['alertInfo', 'message'], action.body.message)
				.set('isAlertOpen', true);
		}
		case GET_MESSAGES: {
			if (action.state === 'finished') {
				state = state.set('messages', action.response.get('messages'));
			}

			return state;
		}

		case SET_SELECTED_PERSON:
			state = state.set(
				'selectedPerson',
				fromJS(action.data.selectedPerson)
			);
			return state;
		case CLEAR_SELECTED_PERSON_INFO:
			state = state.set('selectedPerson', fromJS({}));
			return state;

		default: {
			if (action.state === 'finished') {
				if (action.requestType === 'POST' && action.message) {
					state = state
						.setIn(['alertInfo', 'severity'], 'success')
						.setIn(['alertInfo', 'message'], action.message)
						.set('isAlertOpen', true);
				}

				if (action.type === LOG_IN) {
					state = state.set('isLoginSuccessful', true);
				}

				return state.set('isOpen', false);
			}
			if (action.state === 'send') {
				return state.set('isOpen', true);
			}
			if (action.state === 'error') {
				return state
					.setIn(
						['alertInfo', 'message'],
						action.response.get('message')
					)
					.setIn(['alertInfo', 'severity'], 'error')
					.set('isAlertOpen', true)
					.set('isOpen', false);
			}

			const userData = authentication.loggedData();

			if (!state.getIn(['selectedUser', 'id']) && userData.loggedIn) {
				state = state
					.setIn(['selectedUser', 'id'], userData.userId)
					.setIn(['selectedUser', 'name'], userData.username);
			}

			/* Set data for the logged account data */
			if (
				!state.getIn(['loggedAccountData', 'userId']) &&
				userData.loggedIn
			) {
				state = state.set('loggedAccountData', fromJS(userData));
			}

			return state;
		}
	}
};

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		general,
		jobs,
		consent,
		selfProfile,
		admin,
		dashboard,
		reports,
		summary,
		persons,
		timeline,
		connection,
		unauthenticatedPages,
		accountPages
	});
export default createRootReducer;
