import React from 'react';
import PropTypes from 'prop-types';

import {
	Typography,
	Box,
	List,
	ListItem,
	ListItemText,
	Divider,
	IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const News = ({ data, onRecordDelete }) => {
	const handleOnDelete = id => {
		onRecordDelete(id);
	};

	return (
		<Box
			bgcolor="#f4f7fa"
			padding="10px 30px"
			style={{
				overflowY: 'scroll'
			}}
			height="500px"
			marginBottom="60px"
		>
			<List>
				{data
					.filter(n => !n.isRead)
					.map((record, index) => (
						<Box>
							<ListItem>
								<ListItemText
									primary={
										<Typography
											variant="subtitle2"
											color="textPrimary"
										>
											{record.title}
										</Typography>
									}
									secondary={
										<Typography
											component="span"
											variant="caption"
										>
											{record.message}
										</Typography>
									}
								/>
								<IconButton
									style={{ transform: 'scale(0.8)' }}
									edge="end"
									aria-label="close"
									onClick={() => handleOnDelete(record.id)}
								>
									<CloseIcon />
								</IconButton>
							</ListItem>
							<Divider />
						</Box>
					))}
			</List>
		</Box>
	);
};

News.propTypes = {
	data: PropTypes.func.isRequired,
	onRecordDelete: PropTypes.object.isRequired
};

export default News;
