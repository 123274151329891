import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	CssBaseline,
	Typography,
	Container,
	Grid,
	Button,
	Paper,
	Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ConfirmationDialog from '@Components/ConfirmationDialog';
import TempLinkDialog from '../../components/TempLinkDialog';
import {
	getProfilesList,
	getProfile,
	resetData,
	sendTempLink,
	getProvider
} from '../../actions';
import { styles } from './styles';

class ProfilesList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tempLinkName: '',
			tempLinkEmail: '',
			person: {},
			isConfirmationDialogOpen: false,
			isTempLinkDialogOpened: false,
			selectedProfile: {}
		};
	}

	componentDidMount = async () => {
		const { personId } = this.props.match.params;
		const { person } = this.props.location.state;
		await this.props.resetData();
		await this.props.getProfilesList(personId);
		this.setState({ person });
	};

	loadProfileData = async profile => {
		const { person } = this.props.location.state;

		this.props.history.push({
			pathname: `/dashboard/${person.id}/profiles/${profile.id}`,
			state: { person, profile }
		});
	};

	generateTempLink = async () => {
		const loggedData = this.props.loggedAccountData;

		const profile = await this.props.getProfile(
			this.state.selectedProfile.id,
			this.state.selectedProfile.profileDetailId
		);

		const profileData = profile.response.toJS();

		const provider = await this.props.getProvider(profileData.provider);
		const tempLink = {
			user: loggedData.userId,
			name: this.state.tempLinkName,
			email: this.state.tempLinkEmail,
			profileId: profileData.id,
			url: 'printProfile',
			content: {
				parentData: provider.response.toJS(),
				responseData: { profile: profileData },
				adminMode: false,
				tempLinkCodeAccess: false
			}
		};

		this.props.sendTempLink(tempLink);

		this.setState({
			tempLinkName: '',
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		});
	};

	handleSendTemporaryLinkData = tempLinkData => {
		this.setState({
			tempLinkName: tempLinkData?.name,
			tempLinkEmail: tempLinkData?.email,
			isConfirmationDialogOpen: true
		});
	};

	handlePrint = () => {
		window.print();
	};

	handleCloseConfirmationDialog = () => {
		this.setState({
			tempLinkName: '',
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		});
	};

	openTempLinkDialog = profile => {
		this.setState({
			selectedProfile: profile,
			isTempLinkDialogOpened: true
		});
	};

	closeTempLinkDialog = () => {
		this.setState({
			isTempLinkDialogOpened: false
		});
	};

	render = () => {
		const { classes, profiles } = this.props;

		const {
			isConfirmationDialogOpen,
			isTempLinkDialogOpened,
			person
		} = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<div className={classes.content}>
					<main>
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
							gutterBottom
							className={classes.header}
						>
							My Health Records
						</Typography>
						<Typography
							variant="h5"
							align="center"
							color="textSecondary"
							paragraph
						>
							Please select a Record
						</Typography>
						<Container className={classes.profilesGrid}>
							<Grid container spacing={5}>
								{profiles.length === 0 && (
									<Grid item xs={12} sm={12} md={12}>
										<Typography
											variant="h6"
											align="center"
											color="textSecondary"
										>
											This person has no connections
										</Typography>
									</Grid>
								)}
								<Container
									className={classes.profilesContainer}
									maxWidth="md"
								>
									<Paper
										style={{
											height: 'calc(100vh - 400px)',
											width: '930px'
										}}
									>
										<VirtualizedTable
											rowCount={profiles.length}
											rowGetter={({ index }) =>
												profiles[index]
											}
											rowHeight={100}
											columns={[
												{
													width: 200,
													label: 'Source',
													dataKey: 'source'
												},
												{
													width: 120,
													label: 'Date',
													dataKey: 'created',
													isDateTime: true
												},
												{
													width: 200,
													label: 'Type',
													dataKey: 'type'
												},
												{
													width: 160,
													label: 'Name',
													dataKey: 'name'
												},
												{
													width: 250,
													label: 'Action',
													dataKey: 'id',
													customComponent: (
														profileId,
														index
													) => (
														<Box>
															<Button
																size="small"
																onClick={() =>
																	this.loadProfileData(
																		profiles[
																			index
																		]
																	)
																}
																variant="contained"
																color="primary"
																style={{
																	marginRight:
																		'10px'
																}}
															>
																View
															</Button>
															{/* <Button
																size="small"
																variant="contained"
																color="primary"
																style={{
																	marginRight:
																		'10px'
																}}
															>
																Print
															</Button> */}
															<Button
																size="small"
																variant="contained"
																color="primary"
																onClick={() => {
																	this.openTempLinkDialog(
																		profiles[
																			index
																		]
																	);
																}}
															>
																Share
															</Button>
														</Box>
													)
												}
											]}
										/>
									</Paper>
								</Container>
							</Grid>
						</Container>
					</main>
				</div>
				<TempLinkDialog
					isDialogOpened={isTempLinkDialogOpened}
					selectedPerson={person}
					handleSendTemporaryLinkData={
						this.handleSendTemporaryLinkData
					}
					handleDialogClose={this.closeTempLinkDialog}
				/>
				<ConfirmationDialog
					open={isConfirmationDialogOpen}
					dialogContent="Are you sure that you want to share your health information with this email?"
					dialogTitle="Confirmation Dialog"
					action={this.generateTempLink}
					close={this.handleCloseConfirmationDialog}
				/>
			</Fragment>
		);
	};
}

ProfilesList.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	profiles: PropTypes.array.isRequired,
	getProfilesList: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
	resetData: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	}),
	location: PropTypes.shape({
		state: PropTypes.shape({
			person: PropTypes.object.isRequired
		})
	}),
	getProvider: PropTypes.func.isRequired,
	loggedAccountData: PropTypes.object.isRequired,
	sendTempLink: PropTypes.func.isRequired
};

ProfilesList.defaultProps = {
	location: {},
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				profiles: state.dashboard
					.getIn(['profileDashboard', 'profilesList'])
					.toJS(),
				loggedAccountData: state.general.get('loggedAccountData').toJS()
			};
		},
		{
			getProfilesList,
			getProfile,
			getProvider,
			sendTempLink,
			resetData
		}
	)(ProfilesList)
);
