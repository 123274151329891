export const breadcrumbNameMap = (to, state) => {
	const pathObject = {
		[`/dashboard/${state && state.person && state.person.id}`]: `${state &&
			state.person &&
			state.person.name} dashboard`,
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/profiles`]: 'My Health Records',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/profiles/${state &&
			state.profile &&
			state.profile.id}`]: `${state &&
			state.profile &&
			(state.profile.name || 'No name')}`,
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/visits`]: 'Visits List',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/health-summaries`]: 'Health Summaries List',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/visits/${state &&
			state.visit &&
			state.visit.id}`]: `${state &&
			state.visit &&
			state.visit.visitName}`,
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/health-summaries/${state &&
			state.visit &&
			state.visit.id}`]: `${state &&
			state.visit &&
			state.visit.visitName}`,
		[`/dashboard/${state &&
			state.person &&
			state.person
				.id}/new-self-profile`]: 'Add Health and Educational Information',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/new-provider-profile`]: 'Add connections',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/edit-self-profile`]: 'Edit self profile',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/covid-report`]: 'Covid Report',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/settings`]: 'Settings',
		'/admin/profileImporter': 'Import Profiles',
		'/admin/requestedZipCodes': 'Requested Zip Codes',
		'/admin/feedbacks': 'MyPHI Feedback',
		'/admin/covidFeedbacks': 'COVID-19 feedback',
		'/admin/riskScoresImporter': 'Import Risk Scores',
		'/admin/users': 'Users Administration',
		'/admin/allProfiles': 'List of active profiles',
		'/admin/providers': 'Providers administration',
		[`/admin/providers/providerCreate/${state &&
			state.providerType}/${state &&
			state.providerTypeId}`]: `Create Provider`,
		[`/admin/providers/providerUpdate/${state &&
			state.providerType}/${state &&
			state.providerTypeId}`]: `Update Provider`,
		[`/admin/allProfiles/profile/${state && state.profileId}`]: `${state &&
			state.profileName}`,
		'/profiles-jobs': 'Jobs',
		[`/${state && state.userId}/edit-account-info`]: 'Edit Account info',
		[`/${state && state.userId}/change-password`]: 'Change Password',
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/export-summary`]: `${state &&
			state.person &&
			state.person.name} Health Overview`,
		[`/dashboard/${state &&
			state.person &&
			state.person.id}/timeline`]: `${state &&
			state.person &&
			state.person.name} timeline`
	};

	return pathObject[to];
};
