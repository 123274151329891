import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import RenderLink from '@Components/RenderLink/index';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	messagesButton: {
		[theme.breakpoints.down('sm')]: {
			padding: 5
		}
	}
}));
const host = process.env.REACT_APP_BACKEND_URL;

const redirectLinksMap = message => {
	const path = {
		createProfile: '/profiles-jobs',
		updateProfile: '/profiles-jobs',
		twoWayAuthentication: `/dashboard/${message && message.personId}`
	};

	return path[message.messageType];
};

const Messages = props => {
	const classes = useStyles();
	const [messages, setMessage] = useState([]);
	const [unreadMessages, setUnreadMessage] = useState([]);
	const [socketInstance, setSocketInstance] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		setMessage(props.messages);
		setUnreadMessage(props.messages);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(props.messages)]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const hasUserId = !!props.userId;
		if (hasUserId) {
			let socket = socketInstance;

			if (socket === null) {
				socket = io(host, {
					query: `userId=${props.userId}`
				});

				setSocketInstance(socket);
			}

			socket.on('message', data => {
				const hasId = messages.find(
					item => item.messageId === data.messageId
				);
				if (!hasId) {
					setMessage([...messages, data]);
					setUnreadMessage([...unreadMessages, data]);
					if (window.location.pathname === '/profiles-jobs') {
						props.getJobs();
					}
				}
			});

			return () => {
				socket.off('connect');
				socket.off('disconnect');
				socket.off('message');
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(messages), JSON.stringify(unreadMessages)]);

	const readMessages = () => {
		socketInstance.emit('readMessages');
	};

	const openMenu = Boolean(anchorEl);

	const handleMenu = event => {
		if (unreadMessages.length > 0) {
			readMessages();
		}

		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setUnreadMessage([]);
	};

	const handleItemMenuClick = () => {
		handleClose();
	};

	return (
		<Fragment>
			<IconButton
				color="inherit"
				onClick={handleMenu}
				className={classes.messagesButton}
			>
				<Badge badgeContent={unreadMessages.length} color="secondary">
					<NotificationsIcon />
				</Badge>
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={openMenu}
				onClose={handleClose}
			>
				{messages.length > 0 ? (
					messages.map(item => (
						<RenderLink
							element={MenuItem}
							onClick={handleItemMenuClick}
							to={{
								pathname: redirectLinksMap(item),
								state: {
									...props.location.state,
									sendSms: true
								}
							}}
						>
							{item.messageBody}
						</RenderLink>
					))
				) : (
					<MenuItem onClick={handleClose}>No new messages</MenuItem>
				)}
			</Menu>
		</Fragment>
	);
};

Messages.propTypes = {
	messages: PropTypes.array.isRequired,
	getJobs: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
	location: PropTypes.object
};

Messages.defaultProps = {
	location: {}
};

export default Messages;
