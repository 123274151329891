import * as actionTypes from './actionTypes';

export const getPersonsList = userId => {
	return {
		type: actionTypes.GET_PERSONS,
		requestType: 'GET',
		request: `/people?user=${userId}`,
		body: {
			userId
		}
	};
};

export const photoUpload = form => {
	return {
		type: actionTypes.PHOTO_UPLOAD,
		requestType: 'POST',
		request: `/photoUpload`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const updatePerson = personData => {
	return {
		type: actionTypes.UPDATE_PERSON,
		requestType: 'POST',
		message: 'Person updated successful.',
		request: `/updatePerson`,
		body: {
			...personData
		}
	};
};
